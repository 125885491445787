



























































































































































































































































































import { Getter, Action } from 'vuex-class';
import { MediaHelper } from '@/mixins/MediaHelper';
import { Component, Mixins, Vue } from 'vue-property-decorator';
// @ts-ignore
import { cloneDeep } from 'lodash';

@Component({
    components: {
        PagePresetFullWidth: () => import('@/layouts/presets/FullWidth.vue'),
        FormBase: () => import('@/components/forms/Base.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        FormButton: () => import('@/components/forms/Button.vue'),
        ElButton: () => import('@/components/elements/Button.vue'),
        contactToggle: () => import('@/components/contact/ContactToggle.vue'),
    },
})
export default class PageProfile extends Mixins(MediaHelper) {
    @Getter('user/user') user!: Auth;
    @Getter('user/isAdmin') isAdmin!: boolean;
    @Getter('user/isSupervisor') isSupervisor!: boolean;
    @Getter('company/viewing') company!: Company;
    @Getter('pageLoading') pageLoading!: boolean;

    @Action('user/me') me!: (dontUseStorage: boolean) => Promise<Auth>;
    @Action('user/logout') logout!: () => Promise<Auth>;
    @Action('user/forgotPassword') forgotPassword!: (payload: ForgotPasswordPayload) => Promise<Auth>;
    @Action('account/update') updateUser!: (payload: Auth) => Promise<Auth>;

    activeSection: string = '';

    i18n: string = 'views.profile';

    localUser: Auth | undefined | null = null;

    $refs!: Vue['$refs'] & {
        personalInfoForm: FormClass;
    };

    personalInfo: {
        image: File | string;
        first_name: string;
        last_name: string;
        email: string;
        phone: string;
    } = {
        image: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
    };

    forgotPasswordLoading: boolean = false;
    forgotPasswordSuccess: boolean = false;
    forgotPasswordServerError: boolean = false;

    mounted() {
        this.localUser = cloneDeep(this.user);

        if (this.localUser) {
            this.mapLocalData(this.localUser);
        }
    }

    mapLocalData(user: Auth) {
        this.personalInfo.email = user.email;
        this.personalInfo.first_name = user.first_name;
        this.personalInfo.last_name = user.last_name;
        this.personalInfo.phone = user.phone;
    }

    handleLogout() {
        this.logout().then(() => {
            location.reload();
        });
    }

    uploadImage() {
        const payload = this.localUser;

        if (payload && this.personalInfo.image != null) {
            // @ts-ignore
            const imageArray: File[] = this.personalInfo.image;

            this.prepareMediaArray(imageArray).then((response: ProductImage[]) => {
                if (response.length > 0) {
                    payload.media = response[0];
                }

                payload.active = payload.active ? payload.active : true;
                payload.phone = payload.phone ? payload.phone : '';

                this.updateUser(payload).then((r: Auth) => {
                    this.localUser = r;
                    this.me(true);
                });
            });
        }
    }

    private forgotPasswordSubmit() {
        this.forgotPasswordLoading = true;
        this.forgotPasswordSuccess = false;
        this.forgotPasswordServerError = false;

        const payload = {
            email: this.personalInfo.email,
        };
        this.forgotPassword(payload).then(() => {
            this.forgotPasswordLoading = false;
            this.forgotPasswordSuccess = true;
        });
        // .catch((e: ErrorResponse) => $form.handleErrors(e));
    }

    private formatDeliveryDays(dayArray: string[]) {
        let days = '';

        dayArray.forEach((d) => {
            days += `${this.$t(`days.full.${d}`)}, `;
        });

        if (days.length > 0) {
            days = days.slice(0, -2);
        }

        return days;
    }

    private personalInfoSubmit() {
        const $form: FormClass = this.$refs.personalInfoForm;

        const payload = this.localUser;

        if (payload) {
            payload.email = this.personalInfo.email;
            payload.first_name = this.personalInfo.first_name;
            payload.last_name = this.personalInfo.last_name;
            payload.phone = this.personalInfo.phone ? this.personalInfo.phone : '';
            payload.active = payload.active ? payload.active : true;
            payload.media = payload.media ? payload.media : null;

            this.updateUser(payload)
                .then((r: Auth) => {
                    this.localUser = r;
                    this.me(true);
                    $form.handleSuccess();
                })
                .catch((e: ErrorResponse) => {
                    $form.handleSuccess();
                });
        }
    }
}
