import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export class MediaHelper extends Vue {
    prepareMediaArray(files: File[]): Promise<ProductImage[]> {
        return new Promise((resolve, reject) => {
            const mediaArray: ProductImage[] = [];
            const promises = [];

            for (let i = 0; i < files.length; i++) {
                promises.push(
                    this.toBase64(files[i])
                        .then((result) => {
                            if (typeof result === 'string') {
                                mediaArray.push({
                                    name: files[i].name,
                                    path: result,
                                    order: i + 1,
                                });
                            }
                        })
                        .catch((e: ErrorResponse) => {
                            return reject(e);
                        }),
                );
            }

            Promise.all(promises).then(() => resolve(mediaArray));
        });
    }

    toBase64(file: File): Promise<string | ArrayBuffer | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
}
